import Vue from "vue";

export default Vue.mixin({
    methods: {},
    data() {
        return {
            Global_Config: {
            }
        };
    }
});

